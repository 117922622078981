import React from 'react'

const WordSuggestion = ({ word, onWordSelected }) => {
    const handleClick = () => {
        onWordSelected(word);
    }

    return (
        <span className="word-suggestion" onClick={handleClick}>
            {word}
        </span>
    )
}

export default WordSuggestion;
