import React, { Fragment, useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import { actionTypes } from '../../reducer';
import { GetNegativeTermsList, UpdateNegativeTermsList } from '../../Services';
import { useStateValue } from '../../StateProvider';
import Header from '../header/Header'
import './NegativeListManagement.css';

const NegativeListManagement = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const [{ negativeTerms }, dispatch] = useStateValue();
    const [negativeTermsText, setNegativeTermsText] = useState('');

    useEffect(() => {
        getNegativeTerms();
    }, []);

    const getNegativeTerms = async () => {
        const response = await GetNegativeTermsList();
        let termsData = null;

        if (response.IsSuccessful) {
            termsData = JSON.parse(response.Data);

            dispatch({
                type: actionTypes.SET_NEGATIVE_TERMS,
                negativeTerms: termsData
            });
        } else {
            toast.error(response.Message);
        }

        if (termsData) {
            let termsString = '';

            termsData.map((term) => {
                termsString += term.toLowerCase() + '\n';
            });

            setNegativeTermsText(termsString);
        }

        setIsLoading(false);
    }

    const handleChange = (e) => {
        setNegativeTermsText(e.target.value);
    }

    const handleUpdateTerms = async () => {
        setIsSaving(true);
        let tempNegativeTermsText = negativeTermsText;

        let terms = tempNegativeTermsText.split('\n');

        if (terms && terms.length > 0) {
            let newTermsList = new Array();

            terms.map((term) => {
                newTermsList.push(term.toLowerCase());
            });

            let data = {
                Terms: JSON.stringify(newTermsList)
            };

            const response = await UpdateNegativeTermsList(data);

            if (response.IsSuccessful) {
                dispatch({
                    type: actionTypes.SET_NEGATIVE_TERMS,
                    negativeTerms: newTermsList
                });

                toast.success(response.Message);
            } else {
                toast.error(response.Message);
            }
        }

        setIsSaving(false);
    }

    return (
        <Fragment>
            <Header />

            {!isLoading ? (
                <Fragment>
                    <div className="container negative-terms-container">
                        <h3>Negative Terms</h3>
                        <textarea rows={14} value={negativeTermsText} onChange={handleChange} className="form-control form-control-lg mt-3"></textarea>

                        <button type="button" className="btn btn-primary mt-3" onClick={handleUpdateTerms}>
                            {!isSaving ? (
                                <span>Update Terms</span>
                            ) : (
                                    <div className="spinner-border"></div>
                                )}
                        </button>
                    </div>
                </Fragment>
            ) : (
                    <div className="text-center">
                        <div className="spinner-border"></div>
                    </div>
                )}

        </Fragment>
    )
}

export default NegativeListManagement;
