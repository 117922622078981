export const initialState = {
	editorJson       : null,
	wordsLimit       : 1750,
	writingTime      : 0,
	negativeTerms    : null,
	thesaurusKeyword : '',
	host             : window.location.origin,
	apiHost          : window.location.origin + '/Services/'
};

export const actionTypes = {
	SET_EDITOR_JSON       : 'SET_EDITOR_JSON',
	SET_NEGATIVE_TERMS    : 'SET_NEGATIVE_TERMS',
	SET_WRITING_TIME      : 'SET_WRITING_TIME',
	SET_WORDS_LIMIT       : 'SET_WORDS_LIMIT',
	SET_THESAURUS_KEYWORD : 'SET_THESAURUS_KEYWORD'
};

const reducer = (state, action) => {
	// console.log(action);
	switch (action.type) {
		case actionTypes.SET_EDITOR_JSON:
			return {
				...state,
				editorJson : action.editorJson
			};
		case actionTypes.SET_WRITING_TIME:
			return {
				...state,
				writingTime : action.writingTime
			};
		case actionTypes.SET_NEGATIVE_TERMS:
			return {
				...state,
				negativeTerms : action.negativeTerms
			};
		case actionTypes.SET_WORDS_LIMIT:
			return {
				...state,
				wordsLimit : action.wordsLimit
			};
		case actionTypes.SET_THESAURUS_KEYWORD:
			return {
				...state,
				thesaurusKeyword : action.thesaurusKeyword
			};
		default:
			return state;
	}
};

export default reducer;
