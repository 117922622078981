import { Fragment, useEffect, useState } from 'react';
import { useStateValue } from '../../StateProvider';
import ThesaurusFrame from './thesaurusFrame/ThesaurusFrame';
import './ThesaurusPanel.css';

const ThesaurusPanel = () => {
	const [ expandedClass, setExpandedClass ] = useState('');
	const [ isExpanded, setIsExpanded ] = useState(false);
	const [ { thesaurusKeyword }, dispatch ] = useStateValue();
	const [ thesaurusFrameUrl, setThesaurusFrameUrl ] = useState('');

	useEffect(
		() => {
			const baseUrl = 'https://thesaurus.com';

			if (thesaurusKeyword && thesaurusKeyword !== '') {
				setThesaurusFrameUrl(`${baseUrl}/browse/${thesaurusKeyword}`);
				if (!isExpanded) {
					toggleThesaurusPanel();
				}
			} else {
				setThesaurusFrameUrl(baseUrl);
			}
		},
		[ thesaurusKeyword ]
	);

	const toggleThesaurusPanel = () => {
		setIsExpanded((prev) => !prev);
	};

	useEffect(
		() => {
			if (isExpanded) {
				setExpandedClass('expanded');
			} else {
				setExpandedClass('');
			}
		},
		[ isExpanded ]
	);

	return (
		<div className={`thesaurus-panel ${expandedClass}`}>
			{!isExpanded ? (
				<Fragment>
					<div
						className="thesaurus-panel-toggle"
						onClick={toggleThesaurusPanel}
					>
						<span>Thesaurus</span>
					</div>
				</Fragment>
			) : (
				<Fragment>
					<div className="thesaurus-panel-content">
						<div className="thesaurus-actions-container">
							<a
								className="btn btn-close"
								onClick={toggleThesaurusPanel}
							>
								<i className="fas fa-times" />
							</a>

							<a
								href={thesaurusFrameUrl}
								target="_blank"
								className="btn btn-open-thesaurus"
							>
								<i className="fas fa-external-link-alt" />
							</a>
						</div>
						<ThesaurusFrame />
					</div>
				</Fragment>
			)}
		</div>
	);
};

export default ThesaurusPanel;
