import React, { useEffect, useRef, useState } from 'react';
import { actionTypes } from '../../reducer';
import { useStateValue } from '../../StateProvider';
import ThesaurusDialog from '../thesaurusDialog/ThesaurusDialog';

const WordComponent = ({ wordDetails, onWordSelected }) => {
	const [ className, setClassName ] = useState('');
	const [ thesaurusVisible, setThesaurusVisible ] = useState(false);
	const [ { thesaurusKeyword }, dispatch ] = useStateValue();
	const wordRef = useRef(null);

	useEffect(() => {
		if (wordDetails.thesaurus) {
			setClassName('thesaurus');
		}
	}, []);

	useEffect(
		() => {
			window.addEventListener('mousedown', handleClickOutside);

			return () => {
				window.removeEventListener('mousedown', handleClickOutside);
			};
		},
		[ wordRef ]
	);

	const hideThesaurus = () => {
		setThesaurusVisible(false);
	};

	const handleOnClick = () => {
		if (wordDetails.thesaurus) {
			//setThesaurusVisible(true);

			dispatch({
				type             : actionTypes.SET_THESAURUS_KEYWORD,
				thesaurusKeyword : wordDetails.word
			});
		}
	};

	const handleClickOutside = (e) => {
		if (wordRef.current && !wordRef.current.contains(e.target)) {
			hideThesaurus();
		}
	};

	const handleWordSelected = (word) => {
		onWordSelected(word);
		hideThesaurus();
	};

	return (
		<div ref={wordRef} className="word-wrapper">
			<div
				className={`word ${className}`}
				dangerouslySetInnerHTML={{
					__html : '<span>' + wordDetails.originalWord + '</span> '
				}}
				onClick={handleOnClick}
			/>

			{thesaurusVisible && (
				<ThesaurusDialog
					word={wordDetails.word}
					onWordSelected={handleWordSelected}
				/>
			)}
		</div>
	);
};

export default WordComponent;
