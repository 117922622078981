import { Fragment } from 'react';
import './GroupButton.css';

const GroupButton = ({ onClick, buttonText }) => {
	const handleClick = () => {
		if (onClick) {
			onClick();
		}
	};

	return (
		<Fragment>
			<a className="btn-group" onClick={handleClick}>
				<span>{buttonText}</span>
			</a>
		</Fragment>
	);
};

export default GroupButton;
