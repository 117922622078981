import { Fragment, useEffect, useState } from 'react';
import { useStateValue } from '../../../StateProvider';
import './ThesaurusFrame.css';

const ThesaurusFrame = () => {
	const [ { thesaurusKeyword }, dispatch ] = useStateValue();
	const [ thesaurusFrameUrl, setThesaurusFrameUrl ] = useState('');

	useEffect(
		() => {
			const baseUrl = 'https://thesaurus.com';

			if (thesaurusKeyword && thesaurusKeyword !== '') {
				setThesaurusFrameUrl(`${baseUrl}/browse/${thesaurusKeyword}`);
			} else {
				setThesaurusFrameUrl(baseUrl);
			}
		},
		[ thesaurusKeyword ]
	);

	return (
		<Fragment>
			<div className="thesaurus-frame-container">
				<iframe border="0" src={thesaurusFrameUrl} />
			</div>
		</Fragment>
	);
};

export default ThesaurusFrame;
