import axios from 'axios';

const apiHost = window.location.origin + '/Services/';

export const CreateJson = async (jsonData) => {
    const response = await axios
        .post(apiHost + 'Service.asmx/CreateJson', jsonData, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then((response) => {
            return response;
        })
        .catch((err) => {
            return { data: { d: { IsSuccessful: false, Message: err } } };
        });

    return response.data.d;
};

export const GetJson = async (jsonData) => {
    const response = await axios
        .post(apiHost + 'Service.asmx/GetJson', jsonData, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then((response) => {
            return response;
        })
        .catch((err) => {
            return { data: { d: { IsSuccessful: false, Message: err } } };
        });

    return response.data.d;
};

export const SaveJson = async (jsonData) => {
    const response = await axios
        .post(apiHost + 'Service.asmx/SaveSettings', jsonData, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then((response) => {
            return response;
        })
        .catch((err) => {
            return { data: { d: { IsSuccessful: false, Message: err } } };
        });

    return response.data.d;
};

export const GetNegativeTermsList = async () => {
    const response = await axios
        .post(apiHost + 'Service.asmx/GetNegativeTermsList', null, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then((response) => {
            return response;
        })
        .catch((err) => {
            return { data: { d: { IsSuccessful: false, Message: err } } };
        });

    return response.data.d;
};

export const UpdateNegativeTermsList = async (data) => {
    const response = await axios
        .post(apiHost + 'Service.asmx/UpdateNegativeTermsList', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then((response) => {
            return response;
        })
        .catch((err) => {
            return { data: { d: { IsSuccessful: false, Message: err } } };
        });

    return response.data.d;
};