import { Fragment, useEffect, useState } from 'react';
import { actionTypes } from '../../reducer';
import { useStateValue } from '../../StateProvider';
import { v4 as uuidv4 } from 'uuid';
import RewriteElement from '../rewriteElement/RewriteElement';
import './RewriteElementsGroup.css';

const RewriteElementsGroup = ({
	items,
	tagGroup,
	showOriginal,
	showRewrite,
	calculateWordsCount,
	wordsCount
}) => {
	const [ { editorJson }, dispatch ] = useStateValue();
	const [ groupType, setGroupType ] = useState('');
	const sentenceMatch = /([^\.!\?]+[\.!\?]+)|([^\.!\?]+$)/g;

	useEffect(
		() => {
			if (tagGroup.tagName === 'p') {
				setGroupType('paragraph');
			} else if (tagGroup.tagName === 'ul' || tagGroup.tagName === 'ol') {
				setGroupType('other');
			} else if (tagGroup.tagName.indexOf('h') > -1) {
				setGroupType('heading');
			}
		},
		[ tagGroup.tagName ]
	);

	const handleUngroup = () => {
		let tempJson = { ...editorJson };

		let tempTagGroup = { ...tagGroup, guid: uuidv4() };

		let doc = new DOMParser().parseFromString(
			tempTagGroup.items[0].html,
			'text/html'
		);

		if (doc != null && doc.all.length > 0) {
			let items = [];
			let itemCounter = 0;

			for (let i = 0; i < doc.all.length; i++) {
				let currentTag = doc.all[i];

				if (currentTag.nodeName.toLowerCase() == 'p') {
					let itemsArr = currentTag.innerText.match(sentenceMatch);

					if (itemsArr !== null && itemsArr.length) {
						for (let j = 0; j < itemsArr.length; j++) {
							if (itemsArr[j].trim() !== '') {
								let item = {
									id          : itemCounter,
									guid        : uuidv4(),
									parentGuid  : tempTagGroup.guid,
									tagName     : 'p',
									html        :
										'<p>' + itemsArr[j].trim() + '</p>',
									rewriteHtml : '',
									rewrite     : {
										tagName : 'p',
										html    : ''
									}
								};
								items.push(item);
								itemCounter++;
							}
						}
					}
				}
			}

			tempTagGroup = {
				...tempTagGroup,
				items : items
			};

			tempJson.source.tagGroups.splice(tempTagGroup.id, 1, tempTagGroup);

			console.log('setting ungroup node', tempTagGroup, tempJson);

			dispatch({
				type       : actionTypes.SET_EDITOR_JSON,
				editorJson : tempJson
			});
		}
	};

	const handleGroup = () => {
		let tempJson = { ...editorJson };

		let tempTagGroup = { ...tagGroup, guid: uuidv4() };

		let items = [];
		let newItem = {
			id          : 0,
			guid        : uuidv4(),
			parentGuid  : tempTagGroup.guid,
			tagName     : 'p',
			html        : '',
			rewriteHtml : '',
			rewrite     : {
				tagName : 'p',
				html    : ''
			}
		};

		if (tempTagGroup.items.length > 0) {
			let newHtml = '';
			let newRewriteHtml = '';

			for (let i = 0; i < tempTagGroup.items.length; i++) {
				let item = tempTagGroup.items[i];

				newHtml += item.html;
				newRewriteHtml += item.rewrite.html;
			}

			newItem.html = newHtml;
			newItem.rewrite.html = newRewriteHtml;

			items.push(newItem);

			tempTagGroup = {
				...tempTagGroup,
				items : items
			};

			tempJson.source.tagGroups.splice(tempTagGroup.id, 1, tempTagGroup);

			console.log('setting group node', tempTagGroup, tempJson);

			dispatch({
				type       : actionTypes.SET_EDITOR_JSON,
				editorJson : tempJson
			});
		}
	};

	return (
		<div className={`elements-group ${groupType}`}>
			{items && (
				<Fragment>
					{items.map((item) => {
						let showUngroupButton = item.id === 0;

						if (items.length > 1) {
							showUngroupButton = false;
						}

						const showGroupButton =
							items.length > 1 && item.id === 0;

						return (
							<RewriteElement
								key={item.guid}
								rewriteNode={item}
								showOriginal={showOriginal}
								showRewrite={showRewrite}
								calculateWordsCount={calculateWordsCount}
								wordsCount={wordsCount}
								showUngroupButton={showUngroupButton}
								showGroupButton={showGroupButton}
								onUngroup={handleUngroup}
								onGroup={handleGroup}
							/>
						);
					})}
				</Fragment>
			)}
		</div>
	);
};

export default RewriteElementsGroup;
